<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title">
                <div :class="{ 'tab-active': tabIndex == 1, 'tab': tabIndex != 1 }" @click="onTab(1)">企业诊断</div>
                <div :class="{ 'tab-active': tabIndex == 2, 'tab': tabIndex != 2 }" @click="onTab(2)">深度分析</div>
            </div>
            <div class="content-history" v-if="tabIndex == 1">
                <a-table
                    :columns="columns"
                    :data-source="dList"
                    :pagination="pagination"
                    :loading="loading"
                    @change="onPage"
                >
                    <template #actions="{record}">
                        <div class="btn">
                            <a-button @click="$router.push({path: '/PersonalLayout/DiagnosisHistoryDetails', query: { id: record.DIAGREPBASE_ID }})">详情</a-button>
                        </div>
                    </template>
                </a-table>
            </div>
            <div class="content-history" v-else>
                <a-table
                    :columns="columnsC"
                    :data-source="cList"
                    :pagination="pagination1"
                    :loading="loading"
                    @change="onPage1"
                >
                    <template #actions="{record}">
                        <div class="btn">
                            <a-button @click="$router.push({path: '/PersonalLayout/EnterpriseDiagnosis', query: { id: record.cid }})">编辑</a-button>
                            <a-popconfirm
                                title="确定要删除此条记录吗?"
                                ok-text="是"
                                cancel-text="否"
                                @confirm="onRemove(record.cid)"
                                @cancel="cancel"
                            >
                                <a-button style="margin-left: 15px;">删除</a-button>
                            </a-popconfirm>
                            <a-button style="margin-left: 15px;" @click="onGen(record)">提交诊断</a-button>
                        </div>
                    </template>
                    <template #operation="{record}">
                        <div  v-if="record.CREATION_STATUS === 'Y'" >
                            <a-button style="margin-left:20px" type="primary"   @click="repDown(record.ENTERDEPTHREPORT_ID)">查看</a-button>
                            <a-button style="margin-left:20px" type="primary"     @click="repDown(record.ENTERDEPTHREPORT_ID)">下载</a-button>
                        </div>
                    </template>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
export default defineComponent({
    computed: {
        ...mapGetters(['isAuth', 'loginType', 'orgId'])
    },
    setup() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'num',
                key: 'num',
                width: 100,
                align: 'center'
            },
            {
                title: '报告名称',
                dataIndex: 'reportName',
                key: 'reportName',
                align: 'center'
            },
            {
                title: '生成时间',
                dataIndex: 'timeLimit',
                key: 'timeLimit',
                align: 'center'
            },
            {
                title: '操作',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                slots: {
                    customRender: 'actions'
                }
            }
        ]
        const columnsC = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
                align:'center',
                width: 60
            },
            {
                title: '报告名称',
                dataIndex: 'reportName',
                key: 'reportName',
                width: '25%',
                align:'center'
            },
            {
                title: '季度',
                dataIndex: 'jidu',
                key: 'jidu',
                width: '15%',
                align:'center'
            },
            {
                title: '生成时间',
                dataIndex: 'generatedTime',
                key: 'generatedTime',
                width: '15%',
                align:'center'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                align:'center',
                width:'35%',
                slots: {
                    customRender: 'operation'
                }
            }
        ]
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            columnsC
        }
    },
    data () {
        return {
            ENTERPRISE_ID:'',
            dList: [],
            tabIndex: 1,
            pagination: {},
            pagination1: {},
            loading: false,
            cList: [],
            page: 1,
            pageCount: 10,
            page1: 1,
            pageCount1: 10
        }
    },
    methods: {
        onTab (i) {
            this.tabIndex = i
            if (i == 1) {
                this.getDiagrepbaseList()
            } else {
                this.getDeepReportList()
            }
        },
        // 获取企业诊断列表
        getDiagrepbaseList () {
            this.loading = true
            this.dList = []
            this.$store.dispatch('getDiagrepbaseList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.ENTERPRISE_ID, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            this.dList.push({
                                key: parseInt(i) + 1,
                                num: parseInt(i) + 1 + ((this.page - 1) * 10),
                                reportName: datas[i].ENTERPRISE_NAME + '诊断分析报告',
                                timeLimit: datas[i].EXEDATE,
                                DIAGREPBASE_ID: datas[i].DIAGREPBASE_ID
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.dList = []
                    }
                } else {
                    this.pagination.total = 0
                    this.dList = []
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            })
        },
        // // 获取财税列表
        // getCaiShuiList () {
        //     this.loading = true
        //     this.cList = []
        //     this.$store.dispatch('GetDeepReportList', { showCount: this.pageCount1, currentPage: this.page1, ENTERPRISE_ID: this.ENTERPRISE_ID }).then(res => {
        //         this.loading = false
        //         if (res.result === 'success') {
        //             let datas = res.varList
        //             if (datas.length != 0) {
        //                 this.pagination1.total = res.page.totalResult
        //                 for (let i in datas) {
        //                     this.cList.push({
        //                         key: parseInt(i) + 1,
        //                         xuhao: parseInt(i) + 1,
        //                         cid: datas[i].ENTERCAISHUI_ID,
        //                         niandu: datas[i].YEAR + '年度',
        //                         YEAR: datas[i].YEAR
        //                     })
        //                 }
        //             } else {
        //                 this.pagination1.total = 0
        //                 this.cList = []
        //             }
        //         } else {
        //             this.pagination1.total = 0
        //             this.$message.error('系统异常，获取财税列表失败，请稍后重试！')
        //         }
        //     })
        // },

        repDown(id){
            window.location.href = this.$downDeepRep+'?ENTERDEPTHREPORT_ID='+id
        },
        // 获取深度报告列表
        getDeepReportList () {
            this.cList = []
            this.loading = true
            this.$store.dispatch('getDeepReportList', {
                showCount: this.pageCount1,
                currentPage: this.page1,
                ENTERPRISE_ID: this.ENTERPRISE_ID,
                tm: new Date().getTime()
            }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            let jd = '', jd1 = ''
                            if (datas[i].QUARTER && datas[i].QUARTER !== '') {
                                jd = datas[i].QUARTER.substring(0,4)
                                jd1 = datas[i].QUARTER.substring(4).replace(/\b(0+)/gi,"")
                            }
                            this.cList.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'reportName': datas[i].ENTERPRISE_NAME + '深度分析报告',
                                'jidu': jd+'年第'+jd1+'季度',
                                'generatedTime': (datas[i].CREATION_DATE && datas[i].CREATION_DATE !== '') ? datas[i].CREATION_DATE : '',
                                'CREATION_STATUS': datas[i].CREATION_STATUS,
                                'ENTERDEPTHREPORT_ID': datas[i].ENTERDEPTHREPORT_ID,
                            })
                        }
                        datas.map((item,index)=>{
                            item.QUARTER.substring(0,3)
                            console.log(item.QUARTER.substring(0,4))
                        })
                    } else {
                        this.cList = []
                        this.pagination.total = 0
                    }
                } else {
                    this.rList = []
                    this.pagination.total = 0
                    this.$message.error('系统异常，获取深度报告列表失败，请稍后重试！')
                }
            })
        },
        // 生成诊断报告
        onGen (item) {
            this.$store.dispatch('genDiagnosisReport', { ENTERPRISE_ID: this.orgId, YEAR: item.YEAR, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('生成诊断报告成功！')
                } else {
                    this.$message.error('系统异常，生成诊断报告失败，请稍后重试！')
                }
            })
        },
        // 删除历史数据
        onRemove (item) {
            this.$store.dispatch('deleteCaiShui', { ENTERCAISHUI_ID: item, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getCaiShuiList()
                } else {
                    this.$message.error('系统异常，删除失败，请稍后重试！')
                }
            })
        },
        onPage (pagination) {
            this.page = pagination.current
            this.getDiagrepbaseList()
        },
        onPage1 (pagination) {
            this.page1 = pagination.current
            this.getCaiShuiList()
        }
    },
    created () {
        console.log(this.$route.query)
        this.ENTERPRISE_ID = this.$route.query.ENTERPRISE_ID
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        // 获取企业诊断列表
        this.getDiagrepbaseList()
    }
})
</script>
<style lang="less" scoped>
.content-history ::v-deep(.ant-table-thead){
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.content-history ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            align-items: center;
            .tab {
                padding: 2px 15px;
                color: #A7A7A7;
                cursor: pointer;
            }
            .tab-active{
                padding: 2px 15px;
                color: #ffffff;
                cursor: pointer;
                background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
        .contents{
            text-align: center;
            .ant-btn{
                padding: 0 120px;
                height: 40px;
                color: @color-ff;
                background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
            }
        }
        .content-history{
            margin-top: 20px;
            font-size: 16px;
            .btn{
                height: 40px;
                line-height: 40px;
                ::v-deep(.ant-btn){
                    padding:0 10px;
                    color: #ffffff;
                    border-radius: 5px;
                    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
                }
            }
        }
    }
    .pd-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
